import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    ref: "iFrameRef",
    style: { width: '100%', height: '100vh' },
    onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.iframeIsLoaded && _ctx.iframeIsLoaded(...args))),
    title: "Amplication project",
    src: _ctx.amplicationHost + _ctx.projectId,
    id: "amplication"
  }, null, 40, _hoisted_1))
}