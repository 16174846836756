
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const iFrameRef = ref<HTMLIFrameElement | null>(null);
    const route = useRoute();
    const store = useStore();
    const projectId = route.params.projectId;
    const amplicationHost = process.env.VUE_APP_AMPLICATION_HOST;
    const tokenUser = computed(() => {
      return store.getters.userToken;
    });
    const iframeIsLoaded = () => {
      if (iFrameRef.value && iFrameRef.value.contentWindow && tokenUser.value) {
        iFrameRef.value.contentWindow.postMessage(
          tokenUser.value,
          amplicationHost + "login"
        );
      }
    };
    return {
      projectId,
      amplicationHost,
      iFrameRef,
      iframeIsLoaded,
    };
  },
});
